import React, { useState } from 'react';

function Navigation() {

   const [currentItem, setCurrentItem] = useState('home')
   const nameStyle = {
      float: "right"
   }

   return (

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
         <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className={(currentItem === 'home') ? "current" : ""}><a className="smoothscroll" onClick={() => setCurrentItem('home')} href="#home">Home</a></li>
            <li className={(currentItem === 'about') ? "current" : ""}><a className="smoothscroll" onClick={() => setCurrentItem('about')} href="#about">About Me</a></li>
            <li className={(currentItem === 'resume') ? "current" : ""}><a className="smoothscroll" onClick={() => setCurrentItem('resume')} href="#resume">Experience</a></li>
            {/* <li><a className="smoothscroll" href="#portfolio">Works</a></li>
               <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li> */}
            <li className={(currentItem === 'contact') ? "current" : ""}><a className="smoothscroll" onClick={() => setCurrentItem('contact')} href="mailto:annkit87@gmail.com">Connect With Me</a></li>
            <li style={nameStyle}><a className="smoothscroll nav-name" onClick={() => setCurrentItem('home')} href="#home">Ankit Arora</a></li>
         </ul>

      </nav>

   );
}
export default Navigation;
