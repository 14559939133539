import React, { Component } from 'react';
import './App.css';
import portfolio_data from './data/portfolio.json';

import Navigation from './Components/Navigation';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
// import Testimonials from './Components/Testimonials';
// import Portfolio from './Components/Portfolio';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      portfolio: {}
    };

  }

  getportfolio() {
    this.setState({
      portfolio: portfolio_data
    })
  }

  componentDidMount() {
    this.getportfolio();
  }

  render() {

    return (
      <div className="App">
        <Navigation data={this.state.portfolio.main} />
        <Header data={this.state.portfolio.main} />
        <About data={this.state.portfolio.main} />
        <Resume data={this.state.portfolio.resume} />
        {/* <Portfolio data={this.state.portfolio.portfolio} /> */}
        {/* <Testimonials data={this.state.portfolio.testimonials} /> */}
        <Footer data={this.state.portfolio.main} />
      </div >
    )
  }
}

export default App;
